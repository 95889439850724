import { navbar, navItems, footer, domain, loading, init, buttonFactory, announcement, $ } from './components';
import { detail, ignore, substitute, override, route } from './config';
import review from './dashboard';
import { iFramer, sleep, transition } from './utils';
import '../css/common.css';
import '../css/index.css';
import * as asset from '../config/index/menu';
import axios from 'axios';
const account = JSON.parse(sessionStorage.getItem('evp_participant'));
const program = JSON.parse(sessionStorage.getItem('evp_program'));
const viewDuration = process.env.KICKOFF_VIDEO_SECONDS || 724; //724 Delay on load seems to be about 3 seconds. Always add 3 to runtime
const unlock = account !== null ? account.tracked.step1["Kickoff material"] > 0 || account.type == "instructor" || override : false;
let kickoffVideoUrl = process.env.KICKOFF_VIDEO_URL || 'https://www.youtube.com/embed/0AmtHMnk16I?autoplay=1;si=xJZB3vGK_fXPEZ2b&amp;controls=0';

function announcements() {
    $(`#interface`).append(`
        <div class="row">
            <div class="col-sm g-0 p-2 m-1 bg-dark text-bg-dark rounded animate__animated animate__zoomIn">
                <center id="announcements" class="display-4">Announcements</center>
                ${announcement()}
            </div>
        </div>
    `);
}

function kickoffVideo() {
    const screenWidth = $(window).width();
    const screenHeight = $(window).height();
  
    // Calculate the dimensions for the iframe
    const iframeWidth = screenWidth * 0.8;  // 80% of screen width
    const iframeHeight = screenHeight * 0.8; // 80% of screen height

    $('#interface').append(`
        <div class="row">
            <div id="announcements" class="col-sm g-0 p-5 m-1 bg-dark text-bg-dark rounded animate__animated animate__zoomIn">
                <center class="display-4">Announcements</center>
                <div id="announcement" class="w-80 m-5">
                <p class="text-center">Welcome to the EdVenture Partners Program Participant Portal.</p>
                <p class="text-center">To gain full access to the portal, please watch your client's kickoff video first.</p>
                <p class="text-center">This will ensure you're well-informed about the client's objectives and goals.</p>
                ${buttonFactory('kick-off-material','Play Video', 'primary')}
                </div>
            </div>
        </div>
    `);

    $('#kick-off-material').on({'click': () => {
        $('#announcements').html(`<center>
            <iframe class="animate__animated animate__zoomIn" width="${iframeWidth}" height="${iframeHeight}" src="${kickoffVideoUrl}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <p class="h3 pt-5">When the video completes, this page will auto refresh</p>
        </center>`);

        $('html, body').animate({ scrollTop: $("#announcements").offset().top }, 500);

        sleep(viewDuration * 1000).then(() => {
            // Increment the watched video counter both locally and on the server.
            account.tracked.step1["Kickoff material"]++;
            sessionStorage.setItem('evp_participant', JSON.stringify(account));
            kickOffVideoCounter();
        });
    }});
}

async function kickOffVideoCounter(retries=0) {
    try {
        $('#announcements').html(`<center><img id="floating-animation" src="${loading}"></center>`);
        $('html, body').animate({ scrollTop: $("#announcements").offset().top }, 150);
        await axios.put(`${domain}/${route.get('Kickoff material')}`, {uid: account.uid});
        window.location.href = '/';
    } catch(e) {
        ++retries;
        sleep(retries*250).then(()=> {
            if(retries < 5) kickOffVideoCounter(retries);
            else window.location.href = '/';
        }); 
    }
}

function banner() {
    $('#banner').html(`
        <div class="banner-container animate__animated animate__zoomIn">
            <img src="${asset.banner}">
        </div>
    `);
}

async function main() { // procedural build of the page interface and behaviors
    banner(); 
    await sleep(250); // addresses loading speed of elements in the interface
    navbar();
    navItems();
    footer();
    if (unlock) {
        announcements();
        steps();
    } else {
        kickoffVideo();
    }
}

function steps() {
    $(`#interface`).append(`
        <div class="row mt-1">
            <div id="step-one" class="col-sm g-0 m-3 icon-15 glow-button left">
                ${asset.step1()}
            </div>
            <div id="step-two" class="col-sm g-0 m-3 icon-15 glow-button left">
                ${asset.step2()}
            </div>
            <div id="step-three" class="col-sm g-0 m-2 icon-15 glow-button left">
                ${asset.step3()}
            </div>
        </div>
        <div class="row mt-1">
            <div id="step-four" class="col-sm g-0 m-2 icon-15 glow-button left">
                ${asset.step4()}
            </div>
            <div id="step-five" class="col-sm g-0 m-2 icon-15 glow-button left">
                ${asset.step5()}
            </div>
            <div id="budget-management" class="col-sm g-0 m-2 icon-15 glow-button left">
                ${asset.budget()}
            </div>
        </div>

    `);

    unpackResources(program.resources.step1, "step-one-resources");
    unpackTracking(account.tracked.step1, "step-one-tracking");
    unpackResources(program.resources.step2, "step-two-resources");
    unpackTracking(account.tracked.step2, "step-two-tracking");
    unpackResources(program.resources.step3, "step-three-resources");
    unpackTracking(account.tracked.step3, "step-three-tracking");
    unpackResources(program.resources.step4, "step-four-resources");
    unpackTracking(account.tracked.step4, "step-four-tracking");
    unpackResources(program.resources.step5, "step-five-resources");
    unpackResources(program.resources.budgetManagement, "budget-management-resources");
    unpackTracking(account.tracked.budgetManagement, "budget-management-tracking");
    stepBehaviors();
}

function stepBehaviors() {
    $('#step-one').on({'click':() => transition(() => window.location.href = '1_Program_Activation')});
    $('#step-two').on({'click':() => transition(() => window.location.href = '2_Research_Development')});
    $('#step-three').on({'click':() => transition(() => window.location.href = '3_Implementation')});
    $('#step-four').on({'click':() => transition(() => window.location.href = '4_Wrap_Up')});
    $('#step-five').on({'click':() => transition(() => review(account))});
    $('#budget-management').on({'click':() => transition(() => window.location.href = 'budget')});
}

function unpackResources(resources, elementID) {
    for(let name in resources) {
        //const location = resources[name];
        if(ignore.has(name))
            continue;
        else
            $(`#${elementID}`).append(`<li class="list-group-item text-bg-light">${name}</li>`);
    }
}

function unpackTracking(tracking, elementID) {
    for(let key in tracking) {
        if (key === '_id' || ignore.has(key)) {
            continue;
        }

        let marker = null;
        let color = null;
        if (detail.has(key)) {
            switch(key) {
                case "Budget":
                    marker = `${(Number(tracking[key]) - Number(tracking['Spent'])).toFixed(2)}`;
                    break;
                case "Spent":
                    marker = `${tracking[key].toFixed(2)}`;
                    break;
                default:
                    marker = tracking[key];
            }
            color = marker > 0 ? "color:green;" : "color:red;";
        } else if (Array.isArray(tracking[key])) {
            marker = tracking[key].length;
            color = tracking[key].length > 0 ? "color:green;" : "color:red;";
        } else if (tracking[key] === 0) {
            marker = '&times;';
            color = "color:red;";
        } else {
            marker = '&check;';
            color = "color:green;";
        }

        if(substitute.has(key)) key = substitute.get(key);
        $(`#${elementID}`).append(`<li class="list-group-item text-bg-light">[<span style="${color}"><b>${marker}</b></span>] ${key}</li>`);
    }
}

function verifyYouTubeURL() {
    // embedded url videos apparently cannot contain 2 contiguous parameters. Requires further string manipulation to fix.
    // example url pull from embed option: "https://www.youtube.com/embed/0AmtHMnk16I?si=46yq-9QDicbqdpov&amp;controls=0"
    // this url will not work https://www.youtube.com/embed/0AmtHMnk16I?si=46yq-9QDicbqdpov&amp;controls=0;autoplay=1
    // the last query parameter will be ignored
    // this will require that autoplay is inserted after the ?
    // hence an acceptable url that disables both controls and enables autoplay is
    // https://www.youtube.com/embed/0AmtHMnk16I?autoplay=1;si=xJZB3vGK_fXPEZ2b&amp;controls=0
    if (!kickoffVideoUrl.includes('autoplay=1')) {
        kickoffVideoUrl = `${kickoffVideoUrl};autoplay=1`;
    }
    if (!kickoffVideoUrl.includes('controls=0')) {
        kickoffVideoUrl = `${kickoffVideoUrl};controls=0`;
    }
    console.log(kickoffVideoUrl);
}

$(() => { init(account, main, "homepage"); }); 

$(window).on('resize', iFramer);
  
